import React, { useEffect, useRef } from "react";
import "./App.css"; // Style sheet to control theme
import gohrvisionImage from "./hrvision.jpg"; // Import the image

function App() {
  // Refs for About and Contact sections
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Update the URL when the section comes into view
          if (entry.target.id === "about") {
            window.history.pushState({}, "", "#about");
          } else if (entry.target.id === "contact") {
            window.history.pushState({}, "", "#contact");
          }
        } else {
          // Revert the URL back to the base when sections are not visible
          const currentUrl = window.location.href;
          if (
            currentUrl.includes("#about") ||
            currentUrl.includes("#contact")
          ) {
            window.history.pushState({}, "", "/");
          }
        }
      });
    };

    // Create IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.6, // When 60% of the section is visible
    });

    // Observe the About and Contact sections
    if (aboutRef.current) observer.observe(aboutRef.current);
    if (contactRef.current) observer.observe(contactRef.current);

    // Clean up the observer
    return () => {
      if (aboutRef.current) observer.unobserve(aboutRef.current);
      if (contactRef.current) observer.unobserve(contactRef.current);
    };
  }, []);

  return (
    <div className="App">
      {/* Navigation */}
      <header className="header">
        <div className="logo">
          <h2>GoHRVision</h2>
        </div>
        <nav className="nav">
          <a href="#about" className="about-link">
            About Us
          </a>{" "}
          {/* New About Us link */}
          <a href="#contact" className="contact-link">
            Contact Us
          </a>
        </nav>
      </header>
      <main className="hero-section">
        <div className="hero-content">
          <div className="hero-text">
            <h1>The Only HR Decisioning Platform You Need</h1>
            <h2>To Optimize and Automating Intricate HR Operations</h2>
            <p>
              Leverage technology to streamline HR management, payroll, and
              workforce optimization.
            </p>
            <button className="cta-btn">Get Free Demo</button>
          </div>
          <div className="hero-image-container">
            <img
              src={gohrvisionImage}
              alt="HR platform illustration"
              className="hero-image"
            />
          </div>
        </div>
      </main>

      {/* About Us Section */}
      <section id="about" className="about-section">
        <h2>About Us</h2>
        <p>
          At <strong>GoHRVision</strong>, we are redefining how human resources
          are managed and optimized in today’s fast-paced business world. Our
          mission is to empower organizations with innovative HR solutions that
          streamline processes, enhance employee engagement, and drive overall
          business success.
        </p>
        <p>
          With a focus on flexibility and customization, GoHRVision offers a
          comprehensive platform that caters to the unique needs of each
          organization, enabling HR teams to automate workflows, manage employee
          data seamlessly, and integrate payroll systems effortlessly. Our
          user-friendly interface and cutting-edge features help companies of
          all sizes, from startups to large enterprises, to stay agile and
          efficient in managing their most valuable asset — their people.
        </p>
        <p>
          We believe that every company deserves a tailored HR solution that
          aligns with its goals, culture, and growth plans. That’s why at
          GoHRVision, we are committed to building a future where HR management
          is simple, intuitive, and impactful. Whether you’re looking to
          automate administrative tasks or create a more dynamic and engaged
          workforce, GoHRVision is here to help you succeed.
        </p>
        <h3>Our Vision</h3>
        <p>
          To become the leading platform that revolutionizes human resource
          management through innovation and efficiency.
        </p>
        <h3>Our Mission</h3>
        <p>
          To empower businesses by providing them with scalable and customizable
          HR tools that enhance productivity and employee satisfaction.
        </p>
      </section>

      {/* Contact Us Section with Sign-Up Form */}
      <section id="contact" ref={contactRef} className="contact-section">
        <h2>Contact Us</h2>
        <p>If you have any questions, feel free to reach out to us:</p>
        <div className="contact-details">
          <p>
            <strong>Email:</strong> support@gohrvision.com
          </p>
          {/* <p>
            <strong>Phone:</strong> +91 9918999433
          </p>
          <p>
            <strong>Address:</strong> 123 HR Vision Street, Tech City, CA, 94016
          </p> */}
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 GoHRVision | All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
